<script lang="ts">
  import {
    insertAnswer
  } from "../services/cleanup";
  import { isAdmin, isSuperAdmin } from "../stores/store";

  import { readable_question } from "../utils/readable_question";

  export let finalRowInBlock;
  export let item;
  export let user;
  export let updated = null;
  let error = false;
  let removeError = false;
  export let replacement = "";
  export let projectId;

  let updateEnabled = true;

  const insert = async () => {
      console.log('inserting', user, projectId, item.identifier, replacement);
          updateEnabled = true;
          const result = await insertAnswer(user, projectId, item.identifier, replacement);
            if(result && result.success){
              if(updated){
                  updated();
              }
            }
  }


</script>

<tr class:is-tight={!finalRowInBlock}>
  <td class="remove-padding">
      {#if item.answer == 'placeholder'}
      <button class="btn" class:btnhighlight={!updateEnabled} on:click={() => {updateEnabled = !updateEnabled; removeError = false; error = false;}}>Edit</button>
      {/if}
  </td>


  <td class="is-narrow" class:has-error={removeError}>
    {readable_question(item.identifier)} 
  </td>
  <td>
      {#if item.answer.data}
    {item.answer.data}
    {:else}
    {item.answer}
    {/if}
  </td>
  <td><input class:has-error={error} bind:value={replacement} type="text" /></td>
  <td class="remove-padding">
      <button class="btn" class:hidden={updateEnabled}  on:click={insert}>Insert</button>
  </td>


</tr>

<style lang="scss">
  button {
    line-height: 18px;
    font-weight: normal;

  }

  .is-danger button {
    width: 140px;
  }
  td.is-danger {
    width: 148px;
  }

  tr.is-tight td.is-danger {
    border-color: #fc72cd;
    border-bottom: none;
  }

  td.is-danger:not(:last-child) {
    border-right-width: 0;
    padding-right: 0;
    width: 144px;
  }
  td.is-danger:last-child {
    // padding-left: 0;
    text-align: right;
  }

  th.is-danger {
    width: 160px;
  }
  input.has-error{
    border: 1px solid red;
  }
  td.has-error{
    color: red;
  }
   .hidden{
      visibility: hidden;
  }

  .full-btn{
      width:100%;
  }
</style>
