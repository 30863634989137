<script>
	import { scaleLinear } from 'd3-scale';
	import {ascending} from 'd3'; 

	import {slide } from "svelte/transition";

  export let data, name, xaxis, yaxis, percentage, percentageValue;



	//sort data into ascending 
	//also check there is value otherwise will break
	if(data[0].value){
		data.sort((a, b) => ascending(a.value, b.value));
	}
	let newData = [];
	if(percentageValue != null){

		//check there is a count value otherwise will throw error
		if(data[0].count){
			for(let i=0; i<data.length; i++){
				let Count = (data[i].count/percentageValue) * 100;
				newData.push({value: data[i].value, count: Count});
			}
		}
	}else {
		newData = data;
	}


	//if key of value and count changed wont work
	const xTicks = newData.map((d) => d.value);
	const yTicks = newData.map((d) => d.count);

	function arrayAverage(arr){
    //Find the sum
    var sum = 0;
    for(var i in arr) {
        sum += arr[i];
    }
    //Get the length of the array
    var numbersCnt = arr.length;
    //Return the average / mean.
    return (sum / numbersCnt);
}

	let med = Math.round(arrayAverage(yTicks));

	const padding = { top: 70, right: 30, bottom: 70, left: 50 };

	let width = 500;
	let height = 450;
	let yScale;


	$: xScale = scaleLinear()
		.domain([0, xTicks.length])
		.range([padding.left, width - padding.right]);


	$: if(percentageValue != null){
		yScale = scaleLinear()
		.domain([0, 100])
		.range([height - padding.bottom, padding.top]);
	}else {
		yScale = scaleLinear()
		.domain([0, Math.max.apply(null, yTicks)])
		.range([height - padding.bottom, padding.top]);
	}

	$: innerWidth = width - (padding.left + padding.right);
	$: barWidth = innerWidth / xTicks.length;


</script>

<div class="wrap-h2">
<h2>{name}</h2>
</div>
<div class="chart" bind:clientWidth={width} bind:clientHeight={height}>
	<svg viewBox="0 0 {width} {height}">
		<!-- y axis -->
		<g class="axis y-axis">
			{#each yScale.ticks() as tick}
				{#if (tick - Math.floor(tick)) == 0}
				<g class="tick tick-{tick}" transform="translate({padding.left-20}, {yScale(tick)})">
					<line x2="{width - padding.left}"></line>
					<text y="-4" dx="10">{tick}</text>
				</g>
				{/if}
			{/each}

		</g>

		<!-- x axis -->
		<g class="axis x-axis">
			{#each newData as d, i}
				<g class="tick" transform="translate({xScale(i)},{height})">
					<text x="{barWidth/2}" y="-40">{d.value}</text>
				</g>
			{/each}
		</g>

		<!--axis titles-->
		<text style="text-anchor: middle; font-size:small" x={width/2} y="{height-15}">{xaxis}</text>
		<text style="text-anchor: middle; font-size:small" transform={`translate(${15}, ${height/2}) rotate(-90)`}>{yaxis}</text>

		<!--bars-->
		<g class='bars'>
			{#each newData as d, i}
				<rect
					in:slide={{duration: 500}}
					x="{xScale(i) + 2}"
					y="{yScale(d.count)}"
					width="{barWidth - 4}"
					height="{yScale(0) - yScale(d.count)}"
					fill='#8ad4f8'

				></rect>
			{/each}
		</g>


		<!-- labels values -->
		<g class="x-tick">
			{#each newData as d, i}
				<g class="x-tick" transform="translate({xScale(i)},0)">
					{#if percentageValue != null}
						<text x="{barWidth/2}" y="{yScale(d.count)+10}">{Math.round(d.count)}%</text>
					{:else}
						<text x="{barWidth/2}" y="{yScale(d.count)+10}">{d.count}</text>
					{/if}
				</g>
			{/each}
		</g>

		<g class="axis y-axis">
			<g class="tick tick-{med} average" transform="translate({padding.left-20}, {yScale(med)})">
				<line x2="{width - padding.left}"></line>
			</g>
		</g>

		<g>
			<rect stroke="black" fill="none" x="{width-220}" y="2" width="200" height="55"/>
			<text x="{width-150}" y="20">Legend</text>
        
			<line style="stroke:#ff6a00;stroke-width:2" x1="{width-195}" y1="40" x2="{width-215}" y2="40"/>
			<text x="{width-190}" y="45">Average</text>

		</g>

	</svg>
</div>
