<script lang="ts">
  import { tick } from "svelte";
  import { text } from "svelte/internal";
  import { text_area_resize } from "../utils/autoresize_textarea";
  import { nl2br } from "../utils/nl2br";

  export let value;
  export let change = () => {};
  export let error = "";
  export let textArea = false;
  export let enabled = true;

  let isEditing = false;
  let field;
</script>

{#if isEditing}
  {#if textArea}
    <textarea
      bind:this={field}
      use:text_area_resize
      on:blur={() => {
        if (value != field.value) {
          value = field.value;
          change();
        }
        isEditing = !isEditing;
      }}
      {value}
    />
  {:else}
    <input
      bind:this={field}
      disabled={!enabled}
      on:blur={() => {
        if (value != field.value) {
          value = field.value;
          change();
        }
        isEditing = !isEditing;
      }}
      {value}
      type="text"
    />
  {/if}
{:else}
  <span
    class:has-error={error}
    on:click={async () => {
      if (!enabled) {
        return;
      }
      isEditing = !isEditing;
      await tick();
      field.focus();
      // tick().then(() => {
      //   field.focus();
      // });
    }}>{@html nl2br(value)}</span
  >
{/if}

<!-- <td
  on:click={() => {
    value = "Hi";
    change();
  }}>{value}</td
> -->
<style>
  span {
    display: block;
    min-height: 28px;
  }
  input,
  textarea {
    display: block;
    width: 100%;
  }
  span.has-error {
    border: 1px solid red;
  }
</style>
