<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { addParticipant } from "../services/project";
  import { text_area_resize } from "../utils/autoresize_textarea";
  import { tidy_emails } from "../utils/tidy_emails";
  const dispatch = createEventDispatcher();

  export let projectId;
  let emails = "";
  let errors: Record<string, any> = {};

  async function onSubmit(e) {
    if (emails) {
      const addresses = tidy_emails(emails);
      emails = addresses.join(",");
    }
    const result = await addParticipant(projectId, emails);
    if (result.errors) {
      errors = result.errors;
    } else {
      errors = [];
      emails = "";

      dispatch("created", {
        project: result,
      });
    }
  }
</script>

<div class="create-project">
  <form on:submit|preventDefault={onSubmit} class="form-styled">
    <h3>add participant(s) to community</h3>

    <div class="field-wrapper">
      <label for="name">Email addresses - comma separated to add multiple</label
      >

      <textarea bind:value={emails} use:text_area_resize />
    </div>
    <div>
      <button class="btn" type="submit">add</button>
    </div>
  </form>
</div>

<style>
  .create-project {
    margin-bottom: 2rem;
  }
  h3 {
    margin-bottom: 1em;
  }
</style>
