<script lang="ts">
  import { Jumper } from "svelte-loading-spinners";
</script>

<div>
  <Jumper size="60" color="#000000" unit="px" />
</div>

<style lang="scss">
  div {
    width: 100%;
    height: 200px;
    position: relative;
    :global(div > *) {
      top: 20px;

      margin-left: 50%;
    }
  }
</style>
