<script>
  import { scaleBand, scaleLinear, scaleOrdinal} from "d3-scale";
  import { fly, slide } from "svelte/transition";
  import {select} from 'd3-selection';
  import {transition} from 'd3-transition';
  import {easeLinear} from 'd3-ease';
  import {max} from "d3-array";

  export let data, name, xaxis, yaxis, percentage, percentageValue;

  //const seriesColors = ['#ed6a71', '#8ad4f8'];

  let width =800;
  let height = 450;
  let xScale;

  const margin = { top: 20, right: 20, bottom: 20, left: 180 };
  $: innerHeight = height - margin.top - margin.bottom;
  $: innerWidth = width - margin.left - margin.right;

  //cut down to each object and then each value in, if wanted to make this truely resuable would check that all the keys match and if not add 
  $: groups = data.map(d => d.name);
  $: subgroups = Object.keys(data[0]).filter(key => !isNaN(data[0][key]));
  $: yDomain = data.map(d => Object.values(d)).flat().filter(v => !isNaN(v));

  $: yScale = scaleBand().domain(groups).range([0, innerHeight]).padding([0.1]);
  $: xScale = scaleLinear().domain([0, Math.max.apply(null, yDomain)]).range([0, innerWidth]);
  $: ySubScale = scaleBand().domain(subgroups).range([0, yScale.bandwidth()]);

  $: barColour = scaleOrdinal().domain(subgroups).range(['#ed6a71', '#8ad4f8'])


  



  
</script>

<div class="wrap-h2">
<h2>{name}</h2>
</div>
<div class="chart" bind:clientWidth={width} bind:clientHeight={height}>
<svg viewBox="0 0 {width} {height}">
    <g>
        <rect stroke="black" fill="none" x="1" y="2" width="100" height="90"/>
        <text x="21" y="25">Legend</text>
       {#each subgroups as name, i}
       <g class="bars">
        <rect fill={barColour(name)} x='7' y="{35 + (i*30)}" width="15" height="15"/>
        </g>
        <text class="legend-text" x="30" y="{45 + (i*30)}">{name}</text>-->
        {/each}
    </g>

 <g transform={`translate(${margin.left}, 0)`}>
     {#each xScale.ticks() as tickValue} 
     {#if (tickValue - Math.floor(tickValue)) == 0}
      <g class="tick tick-{tickValue}" transform={`translate(${xScale(tickValue)},0)`}>
        <line y2={innerHeight} stroke="black" />
        <text dy=".71em" y={innerHeight + 3}>
          {tickValue}
        </text>
      </g> 
      {/if}
    {/each}
    <text style="text-anchor: middle; font-size:small" x={innerWidth/2} y="{innerHeight + 35}">{xaxis}</text>
    <text style="text-anchor: middle; font-size:small" transform={`translate(${-160}, ${innerHeight/2}) rotate(-90)`}>{yaxis}</text> 

{#each data as d, i}
	<g transform={`translate(0, ${yScale(d.name)})`}>

		{#each subgroups.map(function(key) {return {key: key, value: d[key]}} ) as g, i}
            <g class="bars">
            <rect 
                x="{0}"
                y="{ySubScale(g.key)}"
                width={xScale(g.value)}
                height={ySubScale.bandwidth()}
                in:fly={{ x: -200, duration: 1000, delay: i * 50 }}
                fill={barColour(g.key)}
            />
            </g>

           <g  class="x-tick">
            <text style="text-anchor:end" 
                x={xScale(g.value)} 
                dy=".4em" 
                y={ySubScale(g.key) + ySubScale.bandwidth() / 2}>
                {g.value}
            </text>
         
           </g>

		{/each}
            <g class="x-tick">
              <text
                x="-2"
                dy=".32em"
                y={yScale.bandwidth()/2}
                class="short-text"
              >
               {groups[i]}
              </text>
            </g>


	</g>
{/each}
</g>
 
</svg>
</div>