import { get } from "./api";

export interface IUserProject {
  name: string;
  slug: string;
  _id: string;
}

export interface IUser {
  _id: string;
  email: string;
  adminRole: string;
  createdAt: string;
  updatedAt: string;
  projects: IUserProject[];
  facilitatorProjects: IUserProject[];
}

export const getUser = async (email: string): Promise<IUser> => {
  const url = `/users/${email}`;
  const data = get(url);
  return data;
};
