<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { createProject } from "../services/project";
  import { slugify } from "../utils/slugify";
  const dispatch = createEventDispatcher();

  let name = "";
  let slug = "";
  let errors: Record<string, any> = {};
  let hasEditedSlugOnce = false;

  $: {
    if (!hasEditedSlugOnce) {
      slug = slugify(name);
    }
  }

  async function onSubmit(e) {
    const formData = new FormData(e.target);

    const data = {};
    for (let field of formData) {
      const [key, value] = field;
      data[key] = value;
    }
    const result = await createProject(data);
    if (result.errors) {
      errors = result.errors;
      console.log("Something went wrong creating project");
    } else {
      errors = [];
      name = "";
      slug = "";
      dispatch("created", {
        project: result,
      });
    }
  }
</script>

<div class="create-project panel">
  <form on:submit|preventDefault={onSubmit} class="form-styled">
    <div class="grid">
      <div class="cell">
        <h3>create new community</h3>
      </div>
      <div class="cell">
        <div class="field-wrapper">
          <label for="name">Project name</label>
          <input
            id="name"
            name="name"
            class:has-error={errors.name}
            bind:value={name}
            type="text"
          />
        </div>
        <div class="field-wrapper">
          <label for="slug">URL Slug</label>
          <input
            name="slug"
            class:has-error={errors.slug}
            on:keyup={() => {
              hasEditedSlugOnce = true;
            }}
            on:blur={() => {
              if (slug === "") {
                hasEditedSlugOnce = false;
              }
            }}
            bind:value={slug}
            type="text"
          />
        </div>
      </div>

      <div class="cell v-align-bottom">
        <div>
          <button class="btn" type="submit">create</button>
        </div>
      </div>
    </div>
  </form>
</div>

<style>
  .create-project {
    margin-bottom: 2rem;
  }

  input.has-error {
    border: 1px solid red;
  }
</style>
