<script lang="ts">
  import { pageTitle } from "../stores/store";
  import DropdownNav from "./DropdownNav.svelte";
  let showingNav = false;

  const clicked = () => {
    showingNav = false;
  };
</script>

<header>
  <span class="is-target"><!--<ProjectSelect />--></span>
  <h1>
    {$pageTitle}
  </h1>
  <div
    class="burger"
    on:click={() => {
      showingNav = !showingNav;
      // page.redirect("/projects");
    }}
  />
  {#if showingNav}
    <div on:click={clicked}>
      <DropdownNav />
    </div>
  {/if}
</header>

<style type="scss">
  header {
    padding: 18px 36px;
    display: flex;
  }

  .burger {
    background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 41.91 39.12'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23d3d2bc%7D.cls-2%7Bfill:none;stroke:%23d7d7d7;stroke-linecap:round;stroke-linejoin:round;stroke-width:.3px%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M.15.15h41.61v9.82H.15z'/%3E%3Cpath class='cls-2' d='M.15.15h41.61v9.82H.15z'/%3E%3Cpath class='cls-1' d='M.15 14.4h41.61v9.82H.15z'/%3E%3Cpath class='cls-2' d='M.15 14.4h41.61v9.82H.15z'/%3E%3Cpath class='cls-1' d='M.15 29.15h41.61v9.82H.15z'/%3E%3Cpath class='cls-2' d='M.15 29.15h41.61v9.82H.15z'/%3E%3C/svg%3E");
    width: 42px;
    height: 40px;
    background-size: 100%;
    background-position: top left;
    background-repeat: no-repeat;
    margin-left: auto;
    cursor: pointer;
  }
</style>
