import { del, get, patch, post, put } from "./api";
import { projects } from "../stores/store";
import { get as getFromStore } from "svelte/store";
import { getSetting } from "./settings";

export const hasKeys = async (id) => {
  const url = `/encryption/${id}`;
  const data = await get(url);
  return data;
};

export const deletePublicKey = async (id) => {
  const url = `/encryption/${id}`;
  const data = await del(url);
  return data;
};

export const restorePublicKey = async (id) => {
  const url = `/encryption/${id}`;
  const data = await put(url, {});
  return data;
};
