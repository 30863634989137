<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { addAdmin } from "../services/admin";
  import { text_area_resize } from "../utils/autoresize_textarea";
  import { slugify } from "../utils/slugify";
  import { tidy_emails } from "../utils/tidy_emails";
  const dispatch = createEventDispatcher();

  let email = "";
  let adminRole = "ADMIN";
  let errors: Record<string, any> = {};

  async function onSubmit(e) {
    const result = await addAdmin(email, adminRole);
    console.log(result);
    if (result.errors) {
      errors = result.errors;
      console.log("Something went wrong creating admin");
    } else {
      errors = [];
      email = "";
      adminRole = "ADMIN";

      dispatch("created", {
        admin: result,
      });
    }
  }
</script>

<div class="create-project">
  <form on:submit|preventDefault={onSubmit} class="form-styled">
    <div class="field-wrapper">
      <h3>add administrator to community</h3>
      <label for="name">Email address</label>

      <input bind:value={email} />
      <label>Role</label>
      <select bind:value={adminRole}>
        <option value="ADMIN">Admin</option>
        <option value="SUPERADMIN">Superadmin</option>
      </select>
    </div>
    <div>
      <button class="btn" type="submit">add</button>
    </div>
  </form>
</div>

<style>
  .create-project {
    margin-bottom: 2rem;
  }
  h3 {
    margin-bottom: 1em;
  }
  input {
    margin-bottom: 1em;
  }
</style>
