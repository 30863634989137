<script>
  import { fade } from 'svelte/transition';
  import { extent, scaleLinear, scaleOrdinal} from "d3";

  import { onMount } from "svelte";
  export let name, data;

  let names = Object.keys(data);
  console.log(names);

  let dataset = data[names[0]];
  let yValue = data[names[1]];
  let xValue = data[names[2]];




  const margin = { top: 70, right: 30, bottom: 70, left: 60 };
  let width = 500;
  let height = 450;
  $: innerWidth = width - margin.left - margin.right;
  $: innerHeight = height - margin.top- margin.bottom;

  let xDomain = dataset.map(d => d[names[2]]);
  let yDomain = dataset.map(d => d[names[1]]);
  let xMin = Math.min.apply(null, xDomain);
  let xMax = Math.max.apply(null, xDomain);
  let yMin = Math.min.apply(null, yDomain);
  let yMax = Math.max.apply(null, yDomain);

  $: xScale = scaleLinear().domain([0, Math.max.apply(null, xDomain)]).range([0, innerWidth]);
  $: yScale = scaleLinear().domain([0, Math.max.apply(null, yDomain)]).range([innerHeight, 0]);
 

   var x = scaleLinear()
        .range([0, innerWidth]);

    var y = scaleLinear()
        .range([innerHeight, 0]);

</script>

<div class="wrap-h2" in:fade={{duration:500}} out:fade={{duration:100}}>
<h2>{name}</h2>
</div>
<div class="chart" bind:clientWidth={width} bind:clientHeight={height}>
  <svg viewBox="0 0 {width} {height}">
    <g transform={`translate(${margin.left},${margin.top})`}>
     <!---- <Axis {innerHeight} {margin} scale={xScale} position="bottom" />
      <Axis {innerHeight} {margin} scale={yScale} position="left" />-->
      <g class="axis y-axis">
			{#each yScale.ticks() as tick}
				{#if (tick - Math.floor(tick)) == 0}
				<g class="tick tick-{tick}" transform="translate(-30, {yScale(tick)})">
					<line x2="{width - margin.left}"></line>
					<text y="-4" dx="10">{tick}</text>
				</g>
				{/if}
			{/each}

	  </g>
      <g class="axis x-axis">
          {#each xScale.ticks() as tick}
				{#if (tick - Math.floor(tick)) == 0}
                    <g class="tick tick-{tick}" transform="translate({xScale(tick)}, {innerHeight + 15})">
                        <text >{tick}</text>
                    </g>
				{/if}
			{/each}
      </g>

      <g>
          <line stroke="#bec2c4" x1="{xScale(xMin)}" x2="{xScale(xMax)}" y1={yScale(yMin)} y2={yScale(yMax)}></line>
      </g>



      <text style="text-anchor: middle; font-size:small" transform={`translate(${-40},${innerHeight / 2}) rotate(-90)`}
        >{names[1]}</text
      >
      {#each dataset as d, i}
        <circle
          cx={xScale(d[names[2]])}
          cy={yScale(d[names[1]])}
          r="5"
          style={d[names[1]] == yValue && d[names[2]] == xValue ? `fill:#21ab4f` : `fill:#003049`}
        />
      {/each}
      <text style="text-anchor: middle; font-size:small" x={innerWidth / 2} y={innerHeight + 35}>{names[2]}</text>
    </g>
  </svg>
  </div>