<script lang="ts">
  import { onMount } from "svelte";
  import { dataset_dev } from "svelte/internal";
  import { getDataForCleanup, onlyEditable } from "../services/cleanup";
  import { getNameQuestionIndexes, getQuestions } from "../services/project";
  import { isAdmin, isSuperAdmin } from "../stores/store";
  import { readable_question } from "../utils/readable_question";
  import Loading from "./Loading.svelte";
  import QuestionRow from "./QuestionRow.svelte";
  export let projectId;
  let cleanupData;
  let filteredData;
  let questions;
  let dangerEnabled = false;
  let dataStrings = [];


  const loadData = async () => {
    questions = await getNameQuestionIndexes(projectId);
    cleanupData = await getDataForCleanup(projectId);
    filteredData = await onlyEditable(cleanupData, questions);
    console.log('filtered', cleanupData);
    dataStrings = [];
   
    //gets the positions of each answer so can delete them
    cleanupData.forEach((data, i) =>{
        let userAnswers = []
        data.data.forEach((qu, index) => {  
            if(qu != null){
                if(qu.hasOwnProperty('answers')){
                    qu.answers.forEach((answer, i) => {
                        if(answer.data != undefined){
                            userAnswers.push({id: qu.identifier, position: i, index: index });
                        }
                    })
                } else{
                    console.log('at index', i, 'question', index, 'there are no answers');
                }
            }else{
                console.log('at index', i, 'question', index, 'there is a null value');
            }


        })
        dataStrings.push(userAnswers);
    })
   // console.log(dataStrings);
  };
  const updated = () => {
    loadData();
  };
  onMount(async () => {
    await loadData();
  });

</script>

<div class="panel" class:show-danger={dangerEnabled}>
  {#if filteredData}
    <table class="tight-input">
      <thead>
        {#if $isAdmin}
        <th class="is-narrow" />
        <th class="is-narrow" />
        <th width="50%">Current</th>
        <th width="50%">New entry</th>
        <th class="is-narrow no-edge" />
        <th class="is-narrow" />
        <th
          class="is-danger"
          colspan="2"
          on:click={() => {
            dangerEnabled = !dangerEnabled;
          }}>Danger zone</th
        >
        {:else}
            <th class="is-narrow"/>
            <th width="50%">Answer</th>
        {/if}
      </thead>
      <tbody>
        {#each filteredData as c, index (index)}
          {#each c.data as item, innerIndex}
            <QuestionRow
              rowsInBlock={c.data.length}
              firstRowInBlock={innerIndex === 0}
              finalRowInBlock={innerIndex + 1 >= c.data.length}
              {item}
              user={c.user}
              {projectId}
              questionAllData={dataStrings[index][innerIndex]}
              {updated}
            
            />

          {/each}
        {/each}
      </tbody>
    </table>
  {:else}
    <Loading />
  {/if}
</div>

<style lang="scss">
</style>
