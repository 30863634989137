<script lang="ts" context="module">
  import { writable, type Writable } from "svelte/store";
  export const activeRoute: any = writable({});

  const routes: Record<string, any> = {};

  export function register(route) {
    routes[route.path] = route;
  }
</script>

<script lang="ts">
  import { onMount, onDestroy } from "svelte";
  import page from "page";
  import Route from "./route.svelte";

  export let disabled = false;
  export let basePath = undefined;

  const last = (route) => {
    return function (ctx) {
      $activeRoute = { ...route, params: ctx.params };
    };
  };

  const setupPage = () => {
    for (let [path, route] of Object.entries(routes)) {
      page(path, ...route.middleware, last(route));
    }
    // set the base url path for our router
    if (basePath) {
      page.base(basePath);
    }

    // setup page and its click handlers
    page.start();
  };

  onMount(setupPage);

  onDestroy(page.stop);
</script>

{#if !disabled}
  <slot />
{/if}
