<script lang="ts">
  import { createLink } from "../services/links";
  import Loading from "./Loading.svelte";

  export let name;
  export let projectId;
  export let project;

  let copyLink = 'Copy Link';

  const create = async () => {
    const result = await createLink(projectId, name.id);
    if (result) {
      name.link = result;
    }
  };

  const copy = async () => {
    const url = `${import.meta.env.VITE_SITE_PREFIX}${project.slug}?access=${
      name.link._id
    }`;
    navigator.clipboard.writeText(url);
    copyLink = 'Link Copied';
    setTimeout(() => {  copyLink = 'Copy Link'; }, 5000);
  };

  const readableLinkStatus = (link) => {
    if (!name.link) {
      return "";
    }
    switch (name.link.status) {
      case "CLAIMED":
        return (
          "Invite used " +
          new Date(name.link.claimedDate).toISOString().split("T")[0] +
          " - "
        );
        break;
      case "CREATED":
        return "Invite created but not used";
        break;
    }
    return "";
  };

  const readableSurveyStatus = (link) => {
    switch (name.status) {
      case "started":
        return "Survey started";
        break;
      case "completed":
        return "Survey completed";
        break;
      default:
        return "";
    }
  };
</script>

<tr class="is-tight">
  <td>{name.count}</td>
  <td>{name.name}</td>
  <td>{readableLinkStatus(name.link)} {readableSurveyStatus(name.link)}</td>
  <td class="remove-padding is-narrow is-link">
    {#if name.status}{""}{:else if name.link}
      <button class="btn" on:click={copy}>{copyLink}</button>
    {:else}
      <button class="btn" on:click={create}>create invitation</button>
    {/if}
  </td>
</tr>

<style lang="scss">
  button {
    line-height: 18px;
    font-weight: normal;
  }

  .is-link button {
    width: 180px;
    border-radius: 2px;
  }
</style>
