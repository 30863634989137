<script lang="ts">
  import { onMount } from "svelte";
  import Loading from "../components/Loading.svelte";
  import ProjectTabs from "../components/ProjectTabs.svelte";
  import { getProjects } from "../services/project";
  import {getStats} from "../services/stats";

  //load in graph components
  import BarCharth from "../components/graphs/barChartH.svelte";
  import BasicStats from "../components/graphs/basicStats.svelte";
  import PieChart from "../components/graphs/pieChart.svelte";
  import StackChart from "../components/graphs/stackedBarChart.svelte";
  import BarChartv from "../components/graphs/barChartV.svelte";

  
  export let projectId;
  let projectStore;
  let projects;
  //setting as first graph
  let selectedGraph = 0;
  //will only ever be two projects 
  let projectids= ['',''];
  let oldProjectids = ['', ''];
  let projectStats = [[], []];
  let displayStats = [true, true];
  let percentage = false;
  let average = false;
  let mostImportant = false;

  //one project needs to be lead project for the drop downmenu
  let firstProject = -1

  //reactivly updates the stats based on ids 
 $: {
	  projectids.forEach(async (id, index) => {
		  if(id != ''){
			  if(oldProjectids[index] != id){
				  percentage = false;
				  average = false;
				  mostImportant = false;
				let stats = await getStats(id);
				if(!stats.ProjectName){
					stats = [];
				} else{
					if(firstProject == -1){
						firstProject = index;
					}
				}
				if(stats.data[0].data['Participants'] < 1){
					displayStats[index] = false;
				} else {
					displayStats[index] = true;
				}
				projectStats[index] = stats;
				oldProjectids[index] = id;
			  }
		  }else {
			  projectStats[index] = [];
		  }		 
	  })
  }

  //update both graphs with the change
  $: {
	  projectStats.forEach(async (project, index) => {
		  if(project.length != 0 ){
			  if(project.data[selectedGraph].hasOwnProperty('percentage')){
				  project.data[selectedGraph].percentage = percentage;
			  }

			  if(project.data[selectedGraph].hasOwnProperty('average')){
				  project.data[selectedGraph].average = average;
			  }

			  if(project.data[selectedGraph].hasOwnProperty('mostImportant')){
				  project.data[selectedGraph].mostImportant = mostImportant;
			  }
			  
		  }
	  });
  }



  onMount(async () => {
    projectStore = await getProjects();
	//filter out the projects with no answers
	projects = $projectStore.filter((p)=> p.questionSet.questions != false);
	
  });

  //returns the correct component based on the graph data
   function componentConstructorClass(componentName){   
      switch(componentName){
        case 'BasicStats': return BasicStats;
        case 'BarCharth': return BarCharth;
        case 'BarChartv': return BarChartv;
        case 'PieChart': return PieChart;
        case 'StackChart': return StackChart;
    }
   }


</script>

<ProjectTabs {projectId} />
<div class="panel">
 {#if projects}
 <pre>
	 <div class="grid">
		 {#each projectStats as project, i}
		 <div class="cell comp-cell">
		 <div class="cell comp-control">
			<div class="grid">
				<div class="cell cell--narrow text-right label compare-cell">Select Project:</div>
				<div class="cell">
					<select class="cell-dropdown" bind:value="{projectids[i]}">
						{#each projects as proj}
							<option value="{proj._id}">{proj.name}</option>
						{/each}
					</select>
				</div>
			</div>
			<div class="grid">
				<div class="cell cell--narrow text-right label compare-cell">Select Graph:</div>
				<div class="cell">

					<select class="cell-dropdown" bind:value={selectedGraph}>
						{#if firstProject != -1}
							{#if projectStats[firstProject].length != 0}
							{#each projectStats[firstProject].data as graph, index} 
								<option value="{index}">{graph.percentage?graph.average?graph.mostImportant?graph.importantAveragePercentageName:graph.averagePercentageName:graph.mostImportant?graph.importantPercentageName:graph.percentageName:graph.average?graph.mostImportant?graph.importantAverageName:graph.averageName:graph.mostImportant?graph.importantName:graph.name}</option>
							{/each}
							{/if}
						{:else}
							<option disabled>Select project first</option>
						{/if}
					</select>

				</div>
			</div>
		</div>
		<div class="cell comp-display">
			<div class="sectionContainer">
				{#if projectids[i] != ''}
					{#if selectedGraph > -1}
						{#if project.length != 0 }
							{#if displayStats[i]}
								{#key project || selectedGraph}
								{#if project.data[selectedGraph].data.length != 0 && 
									Object.entries(project.data[selectedGraph].data).length != 0}
									<div class="compare-btn-container">
										{#if project.data[selectedGraph].hasOwnProperty('percentage')}
										<div class="compare-btn">
											<input id="graph-{selectedGraph}-percentage" type=checkbox bind:checked={percentage}>
											<label class="btn" for="graph-{selectedGraph}-percentage">
												<span>
													{#if percentage}
													 Switch to Values
													{:else}
													 Switch to Percentage
													{/if}
												</span>
											</label> 
											</div>
										{/if}
										{#if project.data[selectedGraph].hasOwnProperty('average')}
										  <div class="compare-btn">
                                                <input id="graph-{selectedGraph}-average" type=checkbox bind:checked={average}>
                                                <label class="btn" for="graph-{selectedGraph}-average">
                                                    <span>
                                                        {#if average}
                                                         Switch to Project Values
                                                        {:else}
                                                         Switch to Comparing Values
                                                        {/if}
                                                    </span>
                                                </label> 
											</div>
										{/if}
									    {#if project.data[selectedGraph].hasOwnProperty('mostImportant')}
											<div class="compare-btn">
                                                <input id="graph-{selectedGraph}-important" type=checkbox bind:checked={mostImportant}>
                                                <label class="btn" for="graph-{selectedGraph}-important">
                                                    <span>
                                                        {#if mostImportant}
                                                         Switch to All Purposes
                                                        {:else}
                                                         Switch to Most Important Purposes
                                                        {/if}
                                                    </span>
                                                </label> 
											</div>
										{/if}
									</div>
										{#key percentage}
											{#key mostImportant}
												{#key average}	

													<div class="graphContainer" class:toggle="{project.data[selectedGraph].hasOwnProperty('percentage')}">
												
														<svelte:component 
														this={componentConstructorClass(project.data[selectedGraph].average ? (project.data[selectedGraph].hasOwnProperty('averageComponet') ? project.data[selectedGraph].averageComponet : project.data[selectedGraph].component) : project.data[selectedGraph].component)} 
														data={project.data[selectedGraph].mostImportant?project.data[selectedGraph].average?project.data[selectedGraph].percentage?project.data[selectedGraph].importantAveragePercentageData:project.data[selectedGraph].importantAverageData:project.data[selectedGraph].percentage?project.data[selectedGraph].percentageImportantData:project.data[selectedGraph].importantData:project.data[selectedGraph].average?project.data[selectedGraph].percentage?project.data[selectedGraph].averagePercentageData:project.data[selectedGraph].averageData:project.data[selectedGraph].percentage?project.data[selectedGraph].percentageData:project.data[selectedGraph].data}
														name={project.data[selectedGraph].percentage?project.data[selectedGraph].average?project.data[selectedGraph].mostImportant?project.data[selectedGraph].importantAveragePercentageName:project.data[selectedGraph].averagePercentageName:project.data[selectedGraph].mostImportant?project.data[selectedGraph].importantPercentageName:project.data[selectedGraph].percentageName:project.data[selectedGraph].average?project.data[selectedGraph].mostImportant?project.data[selectedGraph].importantAverageName:project.data[selectedGraph].averageName:project.data[selectedGraph].mostImportant?project.data[selectedGraph].importantName:project.data[selectedGraph].name}
														xaxis={project.data[selectedGraph].percentage ? project.data[selectedGraph].percentageXAxis : project.data[selectedGraph].xaxis} 
														yaxis={project.data[selectedGraph].percentage ? project.data[selectedGraph].percentageYAxis : project.data[selectedGraph].yaxis} 
														percentageValue={project.data[selectedGraph].percentage?project.data[selectedGraph].average?project.data[selectedGraph].averagePercentageValue:project.data[selectedGraph].percentageValue:null}
														percentage={project.data[selectedGraph].percentage}
														/>
													</div>
												{/key}
											{/key}
										{/key}
										
								{:else}
									<div class="comp-info">
										There is not enough data in the project to display this graph
									</div>
								{/if}
								{/key}
							{:else}
								<div class="comp-info">
									This Project does not have enough data
								</div>
							{/if}
						{/if}
					{:else}
							<div class="comp-info">
								Please select a Graph
							</div>
					{/if}
				{:else}
							<div class="comp-info">
								Please select a Project
							</div>
				{/if}
			</div>
		</div>
		
	</div>
	{/each}
  </div>	
</pre>	
  {:else}
    <Loading />
  {/if}
</div>