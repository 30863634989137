<script>
  import { fade } from 'svelte/transition';
  export let data, name, xaxis, yaxis, percentage, percentageValue;


let values = Object.values(data);
let text = Object.keys(data);


//colour 
let colours = ['#d4a5e2'];

/* edit to better language - change in api */
let textTranslation = 
	{'No of orgs named [total]' : 'Organisations',
	'No of people named [total]' : 'People',
	'Total No of nodes':'Nodes',
	'Total no of links':'Links'}
;

function getText(text){
	let newText = textTranslation[text];
	if(!newText){
		return text;
	}
	return newText;
}


</script>
<div class="wrap-h2" in:fade={{duration:500}} out:fade={{duration:100}}>
<h2>{name}</h2>
</div>
<div class="stat-grid">
 {#each values as d, i}
 	<div>
</div>
	<div in:fade={{duration:500}} out:fade={{duration:100}} class="stat-item">
		<div>
		<h2  style="font-size:70px; color:{colours}"> {d}</h2>
		<p>{getText(text[i])}</p>
		</div>
	</div>
 {/each}
 </div>
