<script lang="ts">
  import { onMount } from "svelte";
  import {
    deleteFacilitator,
    deleteParticipant,
    getFacilitators,
    getParticipants,
    getProject,
  } from "../services/project";
  import Loading from "../components/Loading.svelte";
  import ProjectTabs from "../components/ProjectTabs.svelte";
  import CreateParticipant from "../components/CreateParticipant.svelte";
  import { fade } from "svelte/transition";
  import page from "page";
  import { pageTitle } from "../stores/store";
  import { getLinks } from "../services/links";
    import Admins from "./Admins.svelte";
  export let projectId;
  let participants, participantLink;
  let dangerEnabled = false;
  onMount(async () => {
    participants = await getParticipants(projectId);
    participantLink = await participantsLinks(participants);
    console.log(participantLink);
    let project = await getProject(projectId);
    if (project.errors) {
      page.redirect("/projects");
    }
    $pageTitle = project.name;
  });

  const onAdded = async () => {
    participants = await getParticipants(projectId);
    participantLink = await participantsLinks(participants);
  };
  const deleteUser = async (user) => {
    const result = await deleteParticipant(projectId, user.email);
    if (result && result.success) {
      participants = participants.filter((f) => {
        return f.email != user.email;
      });
    }
     participantLink = await participantsLinks(participants);
  };

  //concatinate the participants with the links, sometimes one email has many created links
 const participantsLinks = async (part) => {
     const links = await getLinks(projectId);
     let partsLinks = [];
     for(let i = 0; i<part.length; i++){
         let partLinks = part[i];
         let Links = [];         
         for(let j=0; j<links.length; j++){
             if(links[j].link){
                 if(links[j].link.email == part[i].email){
                     Links.push(links[j]);
                 }
             }
         }
         partLinks.links = Links;
         partsLinks.push(partLinks);
     }
     return partsLinks;
 }

 const isLinked = (f) => {
     //ive decided to see if any are started and then highlight otherwise is claimed
     if(f.links.length > 0){
         let linked = f.links.find(l => l.status === "started");
         if(linked){
             return 'started';
         }
         return 'claimed';
     }


    return 'plain';
 }


</script>

<ProjectTabs {projectId} />
<div class="panel" class:show-danger={dangerEnabled}>
  <div class="grid">
    <div class="cell is-first">
      {#if participantLink}
        <table style="max-width:500px">
          <thead>
            <th>Email address</th>
            <th
              class="is-danger"
              on:click={() => {
                dangerEnabled = !dangerEnabled;
              }}
              width="160">Danger zone</th
            >
          </thead>
          <tbody>
            {#each participantLink as f, index (f._id)}
              <tr>

                <td class="{isLinked(f)}">
                  {f.email}
                </td>


                <td class="is-danger">
                  <button
                    class="btn"
                    on:click={() => {
                      deleteUser(f);
                    }}>Delete</button
                  >
                </td>
              </tr>
            {/each}
            {#if participants.length === 0}
              <tr>
                <td colspan="2">No participants have been created</td>
              </tr>
            {/if}
          </tbody>
        </table>
      {:else}
        <Loading />
      {/if}
    </div>
    <div class="cell">
      <CreateParticipant {projectId} on:created={onAdded} />
      <div style="max-width:300px">
          <table>
              <thead>
                  <th>Link type</th>
                  <th></th>
              </thead>
              <tbody>
                  <tr>
                      <td>Magic link claimed</td>
                      <td class="claimed"></td>
                  </tr>
                  <tr>
                      <td>Forms started from link</td>
                      <td class="started"></td>
                  </tr>
                  <tr>
                      <td>Not used a magic link</td>
                      <td></td>
                  </tr>

              </tbody>
          </table>
      </div>

    </div>
  </div>
</div>

<style lang="scss">
  div.is-first {
    padding-right: 3rem;
  }


  .claimed {
      background-color:rgb(255, 246, 205);
  }

  .started{
      background-color: rgb(225, 220, 76);
  }
</style>
