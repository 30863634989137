<script lang="ts">
  import { onMount } from "svelte";
  import { dataset_dev } from "svelte/internal";
  import { getDataForCleanup, allEditable } from "../services/cleanup";
  import { getNameQuestionIndexes, getQuestions } from "../services/project";
  import { isAdmin, isSuperAdmin } from "../stores/store";
  import { readable_question } from "../utils/readable_question";
  import Loading from "./Loading.svelte";
  import QuestionInsertRow from "./QuestionInsertRow.svelte";
  export let projectId;
  let cleanupData;
  let filteredData;
 // let questions;
  let dangerEnabled = false;
  let dataStrings = [];


  const loadData = async () => {
   // questions = await getNameQuestionIndexes(projectId);
    cleanupData = await getDataForCleanup(projectId);
   
    filteredData = await allEditable(cleanupData.filter((data) => data.data.includes(null) || typeof(data.data[0].answers[0]) != 'object'));
    //console.log(filteredData);
    if(filteredData.length >=1){
        dataStrings = [];
   
        //gets the positions of each answer so can delete them
        cleanupData.forEach((data, i) =>{
            let userAnswers = []
            //console.log(data.data);
            data.data.forEach((qu, index) => {  
                if(qu != null){
                    if(qu.hasOwnProperty('answers')){
                        qu.answers.forEach((answer, i) => {
                            if(answer.data != undefined){
                                userAnswers.push({id: qu.identifier, position: i, index: index });
                            }
                        })
                    } else{
                        console.log('at index', i, 'question', index, 'there are no answers');
                    }
                }else{
                    console.log('at index', i, 'question', index, 'there is a null value');
                }


            })
            dataStrings.push(userAnswers);
        })
       // console.log(dataStrings);
    }
   // console.log(dataStrings);
  };
  const updated = async () => {
    await loadData();
  };
  onMount(async () => {
    await loadData();
  });

</script>

<div class="panel" class:show-danger={dangerEnabled}>
  {#if filteredData}
  {#if filteredData.length >= 1}
    <table class="tight-input">
      <thead>
        <th class="is-narrow" />
        <th class="is-narrow" />
        <th width="50%">Current</th>
        <th width="50%">Entry</th>
        <th class="is-narrow" />
     
      </thead>
      <tbody>
          {#key filteredData}
        {#each filteredData as c, index (index)}
          {#each c.data as item, innerIndex}
            <QuestionInsertRow

              finalRowInBlock={innerIndex + 1 >= c.data.length}
              {item}
              user={c.user}
              {projectId}
              
              {updated}
            
            />

          {/each}
        {/each}
        {/key}
      </tbody>
    </table>
    {:else}
        <h1>The data is all in the correct format</h1>
    {/if}
  {:else}
    <Loading />
  {/if}
</div>

<style lang="scss">
</style>
