<script lang="ts">
  import { onMount } from "svelte";
  import { quill } from "svelte-quill";

  import Loading from "../components/Loading.svelte";
  import { addSetting, getSetting } from "../services/settings";

  import { pageTitle } from "../stores/store";

  $pageTitle = "Manage privacy policy";
  let field;
  let privacy;
  let beginPrivacy;
  const IDENTIFIER = "privacy";
  let hasError = false;
  let loading = true;
  let options = { placeholder: "Enter privacy policy here" };

  const save = async () => {
    hasError = false;
    try {
      const result = await addSetting(IDENTIFIER, privacy);
      alert("Saved");
    } catch (error) {
      hasError = true;
    }
  };
  onMount(async () => {
    let existingContent = await getSetting(IDENTIFIER);
    beginPrivacy = existingContent.content;
    loading = false;
  });
</script>

<svelte:head>
  <link href="//cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet" />
</svelte:head>

{#if !loading}
  <div class="panel">
    <div
      class="editor"
      use:quill={options}
      on:text-change={(e) => {
        privacy = e.detail.html;
      }}
    >
      {@html beginPrivacy}
    </div>

    <!-- <textarea
      class="is-massive"
      class:has-error={hasError}
      bind:this={field}
      value={privacy.content}
    /> -->
    <div>
      <button class="btn" on:click={save}>Save</button>
    </div>
  </div>
{:else}
  <Loading />
{/if}

<style lang="scss">
  textarea {
    margin-bottom: 1em;
  }

  textarea.has-error {
    border: 1px solid red;
  }

  .panel div {
    display: flex;
  }
  button {
    margin-left: auto;
  }

  .editor {
    background: white;
    margin-bottom: 1em;
  }
  .panel :global(.ql-toolbar) {
    background: #e3e2d3;
  }

  .panel .editor :global(*) {
    margin-bottom: 1em;
  }
</style>
