<script lang="ts">
  export let value = "";
</script>

<div>
  <label for="filter">Filter search</label>
  <input id="filter" type="text" bind:value />
</div>

<style lang="scss">
  div {
    margin-bottom: 1em;
    background: #f3f2e3;
    padding: 0.5em 1em;
  }

  label,
  input {
    display: inline-block;
    width: auto;
    padding: 0 0.5em;
  }

  label {
    font-weight: bold;
    margin-right: 1em;
    padding: 0;
  }

  input {
    background: white;
    width: 400px;
  }
</style>
