import { del, get, patch, post } from "./api";

export const getSetting = async (id) => {
  const url = `/settings/${id}`;
  const data = await get(url);
  return data;
};

export const addSetting = async (identifier, content) => {
  const url = `/settings`;
  const data = await post(url, { identifier, content });
  return data;
};
