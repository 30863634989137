import auth0, {
  Auth0Client,
  type Auth0ClientOptions,
  type LogoutOptions,
  type RedirectLoginOptions,
} from "@auth0/auth0-spa-js";
import { get, writable } from "svelte/store";

export interface Auth0Config {
  onRedirectCallback?: Function;
}

const _useAuth0 = () => {
  const auth0Client = writable(null);
  const isAuthenticated = writable(false);
  const isLoading = writable(true);
  const user = writable(null);
  const accessToken = writable(null);
  const error = writable(null);

  const createAuth0Client = async (config: Auth0ClientOptions) => {
    auth0Client.set(await auth0(config));
  };

  const getClient = (): Auth0Client => get(auth0Client);

  const handleAuth0RedirectCallback = async (onRedirectCallback: Function) => {
    const params = new URLSearchParams(window.location.search);
    const hasError = params.has("error");
    const hasCode = params.has("code");
    const hasState = params.has("state");

    if (hasError) {
      error.set(
        new Error(
          params.get("error_description") || "error completing login process"
        )
      );

      return;
    }

    if (hasCode && hasState) {
      const { appState } = (await get(
        auth0Client
      ).handleRedirectCallback()) as RedirectLoginOptions;
      onRedirectCallback(appState);
    }
  };

  const initializeAuth0 = async (config: Auth0Config = {}) => {
    await createAuth0Client({
      domain: import.meta.env.VITE_AUTH0_DOMAIN,
      client_id: import.meta.env.VITE_AUTH0_CLIENT_ID,
      redirect_uri: import.meta.env.VITE_AUTH0_CALLBACK_URL,
      audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    });

    if (!config.onRedirectCallback) {
      config.onRedirectCallback = () =>
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );
    }
    try {
      await handleAuth0RedirectCallback(config.onRedirectCallback);
    } catch (err) {
      error.set(err);
    } finally {
      const client = getClient();
      user.set((await client.getUser()) || null);
      isAuthenticated.set(await client.isAuthenticated());
      isLoading.set(false);
    }
  };

  const login = async (options: RedirectLoginOptions) => {
    if (!auth0Client) {
      return;
    }

    getClient().loginWithRedirect(options);
  };

  const logout = async (options: LogoutOptions) => {
    getClient().logout(options);
  };

  const getAccessToken = async (options?) => {
    return await getClient().getTokenSilently(options);
  };

  return {
    isAuthenticated,
    isLoading,
    user,
    createAuth0Client,
    initializeAuth0,
    login,
    logout,
    getAccessToken,
    accessToken,
  };
};

export const useAuth0 = _useAuth0();
