<script lang="ts">
  import { isAdmin, isSuperAdmin } from "../stores/store";
  import { deleteName, updateName } from "../services/cleanup";
    import InviteRow from "./InviteRow.svelte";

  export let data;
  export let projectId;
  export let removed = null;
  let error = false;
  export let updated = null;
  let replacement = "";
  let updateEnabled = true;

  const update = async () => {
    error = false;
    if (replacement === "") {
      error = true;
      return;
    }
    updateEnabled = true;
    const result = await updateName(projectId, data.id, replacement);
    if (result && result.success) {
      data.name = replacement;
      replacement = "";
      if (updated) {
        updated();
      }
    }
  };

  const del = async () => {
    const result = await deleteName(projectId, data.id);
    if (result && result.success) {
      if (removed) {
        removed();
      }
    }
  };

  const delName = async () => {
    const result = await updateName(projectId, data.id, '');
    if (result && result.success) {
      if (removed) {
        removed();
      }
    }

  };

</script>

<tr class="is-tight" class:highlight={!updateEnabled}>
 {#if $isAdmin}
  <td class="remove-padding">
      <button class="btn" class:btnhighlight={!updateEnabled} on:click={() => {updateEnabled = !updateEnabled;}}>Edit</button>
  </td>
  <td>
    {data.name}
  </td>
  <td class="no-border">
    <input class:has-error={error} bind:value={replacement} type="text" />
  </td>
  <td class="remove-padding is-narrow"
    ><button class="btn" class:hidden={updateEnabled} on:click={update}>global update</button></td
  >
  <td class="is-danger"
    ><button class="btn" on:click={del}>pseudonymise</button></td
  >
  <td class="is-danger">
      <button class="btn" on:click={delName}>remove name</button>
  </td>
  {:else}
   <td>
    {data.name}
  </td>
  {/if}
</tr>

<style lang="scss">
  button {
    line-height: 18px;
    font-weight: normal;
  }
  th.is-danger {
    width: 160px;
  }
    .is-danger button {
    width: 140px;
  }
  td.is-danger {
    width: 148px;
  }

  tr.is-tight td.is-danger {
    border-color: #fc72cd;
    border-bottom: none;
  }

  td.is-danger:not(:last-child) {
    border-right-width: 0;
    padding-right: 0;
    width: 144px;
  }
  td.is-danger:last-child {
    // padding-left: 0;
    text-align: right;
  }

  .hidden{
      visibility: hidden;
  }

  .highlight{
    background-color: #e3e2d3;

    td {
        background-color: transparent;
    }

    .is-transparent {
        background-color: #d4d3bc !important;
    }
  }

  .btnhighlight{
      background-color:#fc72cd;
  }

  .highlight input.has-error {
    border: 1px solid red;
  }

  td.no-border{
      border-right:0px;
  }
</style>
