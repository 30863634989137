import { useAuth0 } from "../services/auth0";
import { writable, get, type Writable } from "svelte/store";
import type { IUser } from "../services/user";
import { getUser } from "../services/user";
import { getProjects } from "../services/project";

let { isAuthenticated, user } = useAuth0;

// Auth / user stuff
export let currentUser: Writable<IUser> = writable(null);
export let authPending: Writable<boolean> = writable(true);
export let loggedOut: Writable<boolean> = writable(false);
export let permissionDenied: Writable<boolean> = writable(false);
export let isAdmin: Writable<boolean> = writable(false);
export let isSuperAdmin: Writable<boolean> = writable(false);
export let role: Writable<string> = writable("");

// Page title
export let pageTitle: Writable<string> = writable("Dashboard");

// List of projects
export let projects: Writable<any[]> = writable(null);

/* When the value of isAuthenticated changes - 
in other words, when the initial work with Auth0 is complete
- we should now call the API to load data for the current user */
isAuthenticated.subscribe(async (authed) => {
  if (authed) {
    const result = await getUser(get(user).email);
    // we have the user
    if (result) {
      // see if the user is at least an admin
      const isAtLeastAnAdmin =
        result.adminRole === "ADMIN" || result.adminRole === "SUPERADMIN";
      // if they're not at least an admin, we need to check they are facilitator of at least one project
      // if not, they should get permission denied
      if (
        !isAtLeastAnAdmin &&
        (!result.facilitatorProjects || result.facilitatorProjects.length === 0)
      ) {
        permissionDenied.set(true);
      } else {
        isAdmin.set(isAtLeastAnAdmin);
        role.set(result.adminRole);
        currentUser.set(result);
        isSuperAdmin.set(result.adminRole === "SUPERADMIN");
      }
    } else {
      // something went wrong - user not permissioned, or doesn't exist
      permissionDenied.set(true);
    }
    // no longer pending auth...
    authPending.set(false);
  } else {
    console.log(
      "Doing nothing yet because not authed - redirect to login may be automatic"
    );
  }
});
