<script lang="ts">
  import {
    deleteUserData,
    updateAnswerForUser,
    updateName,
    deleteAnswer 
  } from "../services/cleanup";
  import { isAdmin, isSuperAdmin } from "../stores/store";

  import { readable_question } from "../utils/readable_question";

  export let firstRowInBlock;
  export let finalRowInBlock;
  export let rowsInBlock;
  export let item;
  export let user;
  export let updated = null;
  let error = false;
  let removeError = false;
  export let replacement = "";
  export let projectId;
  export let questionAllData;

  //console.log(questionAllData);

  //
  let updateEnabled = true;

  // console.log(user, item);

  // const update = async () => {
  //   const result = await updateName(projectId, data.id, replacement);
  //   // if (result && result.success) {
  //   //   data.name = replacement;
  //   //   replacement = "";
  //   // }
  // };

  const globalUpdate = async () => {
    error = false;
    if (replacement === "") {
      error = true;
      return;
    }
    const result = await updateName(projectId, item.answer.id, replacement);
    if (result && result.success) {
      item.answer.data = replacement;
      replacement = "";
      if (updated) {
        updated();
      }
    }
  };
  //i think we need a error message now so they know why its not working
  const update = async () => {
    error = false;

    if (replacement.trim().length === 0) {
      error = true;
      return;
    }
    updateEnabled = true;
    item.allAnswers[item.indexForQuestion] = replacement;
    const answers = item.allAnswers.map((a) => {
      if (typeof a == "object") {
        return a.data;
      }
      return a;
    });
    const result = await updateAnswerForUser(
      projectId,
      user,
      answers,
      item.identifier
    );
    if (result && result.success) {
      item.answer.data = replacement;
      replacement = "";
      if (updated) {
        updated();
      }
    }
  };

  const globalDel = async () => {
    const result = await deleteUserData(projectId, user);
    if (result && result.success) {
      if (updated) {
        updated();
      }
    }
  };

  const del = async () => {
      if(questionAllData.index > 1){
          updateEnabled = true;
          const result = await deleteAnswer(user, projectId, questionAllData.index, questionAllData.position);
            if(result && result.success){
              if(updated){
                  updated();
              }
            }
      } else {
          removeError = true;
          return;
      }
  }


</script>

<tr class:is-tight={!finalRowInBlock}>
 {#if $isAdmin}
  <td class="remove-padding">
      <button class="btn" class:btnhighlight={!updateEnabled} on:click={() => {updateEnabled = !updateEnabled; removeError = false; error = false;}}>Edit</button>
  </td>


  <td class="is-narrow" class:has-error={removeError}>
    {readable_question(item.identifier)} 
  </td>
  <td>
    {item.answer.data}
  </td>
  <td><input class:has-error={error} bind:value={replacement} type="text" /></td>
  {#if item.identifier == 'question--0' || item.identifier == 'question--1'}
   <td class="remove-padding" colspan="2">
      <button class="btn full-btn"  class:hidden={updateEnabled}  on:click={update}>update</button>
  </td>
  {:else}
   <td class="remove-padding no-edge">
      <button class="btn"  class:hidden={updateEnabled}  on:click={update}>update</button>
  </td>
  <td class="remove-padding">
      <button class="btn" class:hidden={updateEnabled}  on:click={del}>remove</button>
  </td>
  {/if}
 
  <td class="is-danger"
    ><button class="btn"  on:click={globalUpdate}>global update</button></td
  >
  {#if firstRowInBlock}
    <td  class="is-danger"
      ><button class="btn"  on:click={globalDel}>delete user</button></td>
{:else}
    <td class="is-danger"/>
    {/if}
    {:else}
    <td class="is-narrow">
    {readable_question(item.identifier)} 
  </td>
  <td>
    {item.answer.data}
  </td>

    {/if}
</tr>

<style lang="scss">
  button {
    line-height: 18px;
    font-weight: normal;

  }

  .is-danger button {
    width: 140px;
  }
  td.is-danger {
    width: 148px;
  }

  tr.is-tight td.is-danger {
    border-color: #fc72cd;
    border-bottom: none;
  }

  td.is-danger:not(:last-child) {
    border-right-width: 0;
    padding-right: 0;
    width: 144px;
  }
  td.is-danger:last-child {
    // padding-left: 0;
    text-align: right;
  }

  th.is-danger {
    width: 160px;
  }
  input.has-error{
    border: 1px solid red;
  }
  td.has-error{
    color: red;
  }
   .hidden{
      visibility: hidden;
  }

  .full-btn{
      width:100%;
  }
</style>
