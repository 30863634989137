<script lang="ts">
  import { getStats } from "../services/stats";
  import { onMount } from "svelte";
  import { fade } from 'svelte/transition';
  import Loading from "../components/Loading.svelte";
  import ProjectTabs from "../components/ProjectTabs.svelte";

  //load in graph components
  import BarCharth from "../components/graphs/barChartH.svelte";
  import BasicStats from "../components/graphs/basicStats.svelte";
  import PieChart from "../components/graphs/pieChart.svelte";
  import StackChart from "../components/graphs/stackedBarChart.svelte";
  import BarChartv from "../components/graphs/barChartV.svelte";
  import BarCharthAverage from "../components/graphs/barChartHAverage.svelte"
  import ScatterPot from "../components/graphs/scatterPlot.svelte";

  export let projectId;
  let statsData;
  let show = true;
  let oldShow = show;
  let displayStats = true;

  onMount(async () => {
    statsData = await getStats(projectId);
    console.log(statsData);
    //if no participants then page wont display
    if(statsData.data[0].data['Participants'] < 1){
        displayStats = false;
    }
  });

  $: if(show != oldShow){
      statsData.forEach((g) => {
            g.show = show;
      })
      oldShow = show;
  }

  //to know which order they display in for css reasons
  $: indexGraph = (x) => {
      if(statsData)
      {
          return statsData.data.filter((g) => g.show == true).findIndex((g) => x == g.name);
      } else {
          return -1;
      }
    }

   //returns the correct component based on the graph data
   function componentConstructorClass(componentName){   
      switch(componentName){
        case 'BasicStats': return BasicStats;
        case 'BarCharth': return BarCharth;
        case 'BarChartv': return BarChartv;
        case 'PieChart': return PieChart;
        case 'StackChart': return StackChart;
        case 'BarCharthaverage': return BarCharthAverage;
        case 'ScatterPlot': return ScatterPot;
    }
   }

</script>

<ProjectTabs {projectId} />
<div class="panel">
  {#if statsData}
    <pre style="min-height:450px;">
         {#if displayStats}
         <div class="checkbox-container">
            <table>
                <thead>
                    <th width="50%">Graph</th>
                    <th class="is-narrow checkbox">
                        <input id="graph-display" type=checkbox bind:checked={show}>
                        <label class="btn" for="graph-display">
                            <span>
                                {#if show}
                                hide
                                {:else}
                                show
                                {/if}
                            </span>
                        </label> 
                    </th>
                </thead>
                <tbody>
                    {#each statsData.data as graph, index}
                    {#key show || graph.percentage || graph.mostImportant || graph.average}
                        <tr in:fade={{duration:500}} out:fade={{duration:500}}>

                            <td width="50%">{graph.percentage?graph.average?graph.mostImportant?graph.importantAveragePercentageName:graph.averagePercentageName:graph.mostImportant?graph.importantPercentageName:graph.percentageName:graph.average?graph.mostImportant?graph.importantAverageName:graph.averageName:graph.mostImportant?graph.importantName:graph.name}</td>

                            <td class="is-narrow checkbox">
                                <input id="graph-{index}-toggle" type=checkbox bind:checked={graph.show}>
                                <label class="btn" for="graph-{index}-toggle">
                                    <span>
                                        {#if graph.show}
                                         hide
                                         {:else}
                                         show
                                        {/if}
                                    </span>
                                </label> 
                            </td>
                        </tr>
                        {/key}
                    {/each}
                </tbody>
            </table>
         </div>
         <div class="grid-stats">
             {#each statsData.data as graph, index}
                {#if graph.show}
                    {#key graph.show || show}
                        <div class="sectionContainer" id="section-{indexGraph(graph.name)}" in:fade={{duration:500}} out:fade={{duration:500}}>
                            <!--check there is data in the graph and if so then draw otherwise display error message-->
                            {#if graph.data.length != 0 && Object.entries(graph.data).length != 0}
                                <div class="stats-btn-container">                                   
                                   {#if graph.hasOwnProperty('percentage')}

                                            <div class="stats-btn">
                                                <input id="graph-{index}-percentage" type=checkbox bind:checked={graph.percentage}>
                                                <label class="btn" for="graph-{index}-percentage">
                                                    <span>
                                                        {#if graph.percentage}
                                                         Switch to Actual Numbers
                                                        {:else}
                                                         Switch to Percentage
                                                        {/if}
                                                    </span>
                                                </label> 
                                                </div>

                                    {/if}
                                    {#if graph.hasOwnProperty('average')}

                                            <div class="stats-btn">
                                                <input id="graph-{index}-average" type=checkbox bind:checked={graph.average}>
                                                <label class="btn" for="graph-{index}-average">
                                                    <span>
                                                        {#if graph.average}
                                                         Switch to Project Values
                                                        {:else}
                                                         Switch Understory Averages
                                                        {/if}
                                                    </span>
                                                </label> 
                                                </div>

                                    {/if}
                                     {#if graph.hasOwnProperty('mostImportant')}

                                            <div class="stats-btn">
                                                <input id="graph-{index}-important" type=checkbox bind:checked={graph.mostImportant}>
                                                <label class="btn" for="graph-{index}-important">
                                                    <span>
                                                        {#if graph.mostImportant}
                                                         Switch to All Purpose
                                                        {:else}
                                                         Switch to Most Important Purpose
                                                        {/if}
                                                    </span>
                                                </label> 
                                                </div>

                                    {/if}
                                </div>
                                <!--have to have each key seperate as they started conflicting then not working-->
                                {#key graph.percentage}
                                    {#key graph.mostImportant}
                                        {#key graph.average}
                                        <div class="graphContainer" class:toggle="{graph.hasOwnProperty('percentage')}">
                                            <svelte:component 
                                            this={componentConstructorClass(graph.average ? (graph.hasOwnProperty('averageComponet') ? graph.averageComponet : graph.component) : graph.component)} 
                                            data={graph.mostImportant?graph.average?graph.percentage?graph.importantAveragePercentageData:graph.importantAverageData:graph.percentage?graph.percentageImportantData:graph.importantData:graph.average?graph.percentage?graph.averagePercentageData:graph.averageData:graph.percentage?graph.percentageData:graph.data}
                                            name={graph.percentage?graph.average?graph.mostImportant?graph.importantAveragePercentageName:graph.averagePercentageName:graph.mostImportant?graph.importantPercentageName:graph.percentageName:graph.average?graph.mostImportant?graph.importantAverageName:graph.averageName:graph.mostImportant?graph.importantName:graph.name}
                                            xaxis={graph.percentage ? graph.percentageXAxis : graph.xaxis} 
                                            yaxis={graph.percentage ? graph.percentageYAxis : graph.yaxis} 
                                            percentageValue={graph.percentage?graph.average?graph.averagePercentageValue:graph.percentageValue:null}
                                            percentage={graph.percentage}
                                            />
                                        </div>
                                        {/key}
                                    {/key}
                                {/key}
                            {:else}

                                <div class="graphContainer">
                                    <h2 class="center">There is not enough data in the project to display this graph</h2>
                                </div>
                            {/if}
                        </div>
                    {/key}
                {/if}
             {/each}
         </div>
         {:else}
            <h1>You need at least one participant to have answered the questions to get statistical data.</h1>
         {/if}
    </pre>
  {:else}
    <Loading />
  {/if}
</div>


