<script>
  import { scaleBand, scaleLinear } from "d3-scale";
  import { fly, slide } from "svelte/transition";
  import {select} from 'd3-selection';
  import {transition} from 'd3-transition';
  import {easeLinear} from 'd3-ease';

  export let data, name, xaxis, yaxis, percentage, percentageValue;

  const seriesColors = ['#ed6a71'];

  /*let newData = {};
	if(percentage != null){
		for(let [key, value] of Object.entries(data)){
            let pValue = Math.round((value/percentage) * 100);
            newData[key] = pValue
        }

	}else {
		newData = data;
	}*/

    let newData = {};
    newData = data;

  let sortedArrayData = Object.entries(newData).sort((a , b) => b[1] -a[1]);
 // console.log('data', sortedArrayData);
 
  let width =800;
  let height = 450;
  let xScale;

  const margin = { top: 20, right: 20, bottom: 20, left: 180 };
  $: innerHeight = height - margin.top - margin.bottom;
  $: innerWidth = width - margin.left - margin.right;


  $: xDomain = sortedArrayData.map((d) => d[0]);
 // console.log('x', sortedArrayData.map((d) => d[0]));
  $: yDomain = Object.values(newData);
 // console.log('y', Object.values(newData));

  $: yScale = scaleBand().domain(xDomain).range([0, innerHeight]).padding(0.1);
 // console.log('yscale', xDomain);
  $: if(percentage)
  {
      xScale = scaleLinear().domain([0, 100]).range([0, innerWidth]);
  } else {
      xScale = scaleLinear().domain([0, Math.max.apply(null, yDomain)]).range([0, innerWidth]);
  }
 // console.log('xscale', Math.max.apply(null, yDomain));

  function limitLength(string){
      return string.substring(0, 15);
  }


  	const reveal = (node, {duration}) => {
        const t = transition().duration(duration).ease(easeLinear);
        select(node).transition(t);
	}
</script>

<div class="wrap-h2">
<h2>{name}</h2>
</div>
<div class="chart" bind:clientWidth={width} bind:clientHeight={height}>
<svg viewBox="0 0 {width} {height}">
  <g transform={`translate(${margin.left}, 0)`}>
     {#each xScale.ticks() as tickValue} 
     {#if (tickValue - Math.floor(tickValue)) == 0}
      <g class="tick tick-{tickValue}" transform={`translate(${xScale(tickValue)},0)`}>
        <line y2={innerHeight} stroke="black" />
        <text dy=".71em" y={innerHeight + 3}>
          {tickValue}
        </text>
      </g> 
      {/if}
    {/each}
    <!--axis titles-->
    <text style="text-anchor: middle; font-size:small" x={innerWidth/2} y="{innerHeight + 35}">{xaxis}</text>
    <text style="text-anchor: middle; font-size:small" transform={`translate(${-160}, ${innerHeight/2}) rotate(-90)`}>{yaxis}</text>

    {#each sortedArrayData as d, i}
     <g class="bars">
          <rect
            x="0"
            y={yScale(d[0])}
            width={xScale(d[1])}
            height={yScale.bandwidth()}
             in:fly={{ x: -200, duration: 1000, delay: i * 50 }}

            fill={seriesColors[0]}/>
      </g>
      <g class="x-tick">
          <text
            x="-2"
            dy=".32em"
            y={yScale(d[0]) + yScale.bandwidth() / 2}
            class="short-text"
          >
            {d[0]}
          </text>
      </g>
      <g  class="x-tick">
          {#if d[1] === 0}
            {#if percentage}
            <text style="text-anchor:start" x={xScale(d[1])} dy=".4em" y={yScale(d[0]) + yScale.bandwidth() / 2}>{d[1]}%</text>
          {:else}
            <text style="text-anchor:start" x={xScale(d[1])} dy=".4em" y={yScale(d[0]) + yScale.bandwidth() / 2}>{d[1]}</text>
          {/if}
          {:else}
            {#if percentage}
            <text style="text-anchor:end" x={xScale(d[1])} dy=".4em" y={yScale(d[0]) + yScale.bandwidth() / 2}>{d[1]}%</text>
          {:else}
            <text style="text-anchor:end" x={xScale(d[1])} dy=".4em" y={yScale(d[0]) + yScale.bandwidth() / 2}>{d[1]}</text>
          {/if}
          {/if}
          
      </g>

    {/each}


   </g>   
</svg>
</div>