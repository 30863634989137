<script lang="ts">
  export let activeIndex = 0;
  export let projectId;
  export let tabs;
</script>

<ul class="tabs">
  {#each tabs as tab, index}
    <li
      class:is-active={index == activeIndex}
      on:click={() => {
        activeIndex = index;
      }}
    >
      {tab.label}
    </li>
  {/each}
</ul>
<div class="tab-wrap">
  {#each tabs as tab, index}
    {#if index === activeIndex}
      <svelte:component this={tab.component} {projectId} />
    {/if}
  {/each}
</div>

<style type="scss">
</style>
