<script>
  import { scaleBand, scaleLinear, max } from "d3";
  import {stack, stackOffsetExpand} from "d3-shape";

    export let data, name, xaxis, yaxis, percentage, percentageValue;

  let dataset = [data];

  let display = true;

  const seriesColors = ['#83d6ef', '#ed6a71', '#4e9fac', '#e0bfce'];
  const margin = { top: 20, bottom: 20, left: 20, right: 20 };
  let width = 400,
    height = 200;

    const seriesNames = Object.keys(dataset[0]);
    const seriesValues = Object.values(dataset[0]);


    if(seriesValues.every(item => item === 0)){
        display = false;
    }

    
    const stackData = stack().offset(stackOffsetExpand).keys(seriesNames);
    const series = stackData(dataset);


    let yScale = scaleBand()
    .domain(seriesNames)
    .range([0, height])
    .paddingInner(0.15);

    $: xScale = scaleLinear()
    .domain([0, 1])
    .range([0, width])


</script>
{#if !display}
     <h2 class="center">There is not enough data in the project to display this graph</h2>
{:else}
<div class="wrap-h2">
<h2>{name}</h2>
</div>
<div class="chart" bind:clientWidth={width} bind:clientHeight={height}>
<svg viewBox="0 0 {width} {height}" class="stacked">
    <g>
        <rect stroke="black" fill="none" x="1" y="10" width="100" height="90"/>
        <text x="21" y="30">Legend</text>
        {#each seriesNames as name, i}
        <circle fill={seriesColors[i]} r='7' cx="11" cy="{60 + i*25}"/>
        <text x="21" y="{65 + i*25}">{name}</text>
        {/each}
    </g>
    {#each series as data, i}
    {#each data as d}
     <g> 
     <rect
        x={xScale(d[0])}
        y="150px"
        width={xScale(d[1]) - xScale(d[0])}
        height={yScale.bandwidth()}
        fill={seriesColors[i]}
      />
      <text font-weight="bold" x={xScale(d[0]) + (xScale(d[1]) - xScale(d[0]))/2} y="{150 + yScale.bandwidth()/2}px">
        {seriesValues[i]}
      </text>
      </g>
      {/each}
    {/each}
    <text x="50%" y="300px" class="stacked-text">
        Out of {data.linking + data.noLinking} participants {data.linking} have linking capital
    </text>
  </svg>
 
</div>
{/if}


