import { del, get, patch, post } from "./api";

export const getAdmins = async () => {
  const url = `/admins?$limit=10000`;
  const data = await get(url);
  return data.data;
};

export const addAdmin = async (email, adminRole) => {
  const url = `/admins`;
  const data = await post(url, { email, adminRole });
  return data;
};

export const deleteAdmin = async (email) => {
  email = encodeURIComponent(email);
  const url = `/admins/${email}`;
  const result = await del(url);
  return result;
};
