<script lang="ts">
  import { onMount } from "svelte";
  import InviteRow from "../components/InviteRow.svelte";
  import Loading from "../components/Loading.svelte";
  import ProjectTabs from "../components/ProjectTabs.svelte";
  import { getLinks } from "../services/links";
  import { getProject } from "../services/project";

  export let projectId;
  let linkData;
  let project;
  let sortedLinkData;
  onMount(async () => {
    project = await getProject(projectId);
    linkData = await getLinks(projectId);

    sortedLinkData = [...linkData];
    sortedLinkData.sort(function (a, b) {
      if (a.count > b.count) {
        return -1;
      } else if (a.count < b.count) {
        return 1;
      } else if (a.name > b.name) {
        return 1;
      }
      return -1;
    });


  });


  // const sortedLinkData = () => {
  //   console.log(linkData);
  //   return linkData.sort(function (a, b) {
  //     console.log(a, b);
  //     return b; //a.count < b.count;
  //   });
  // };
</script>

<ProjectTabs {projectId} />
<div class="panel">
  {#if linkData}

    <table class="tight-input">
      <thead>
        <tr>
          <th class="is-narrow">Mentions</th>
          <th width="50%">named orgs</th>
          <th width="50%" colspan="2">organisation status</th>
        </tr>
      </thead>
      <tbody>
        {#each sortedLinkData as name}
          <InviteRow {name} {projectId} {project} />
        {/each}
      </tbody>
    </table>
  {:else}
    <Loading />
  {/if}
</div>
