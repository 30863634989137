<script lang="ts">
  import { onMount } from "svelte";

  import Loading from "../components/Loading.svelte";
  import { getProject, updateProject } from "../services/project";
  import { addSetting, getSetting } from "../services/settings";

  import { pageTitle } from "../stores/store";

  $pageTitle = "Manage default question set";
  let field;
  let questionSet;
  const IDENTIFIER = "defaultQuestions";
  let hasError = false;

  const save = async () => {
    hasError = false;
    try {
      const questionSet = JSON.parse(field.value);

      const result = await addSetting(IDENTIFIER, field.value);
      alert("Saved");
    } catch (error) {
      hasError = true;
    }
  };
  onMount(async () => {
    questionSet = await getSetting(IDENTIFIER);
  });
</script>

{#if questionSet}
  <div class="panel">
    <textarea
      class="is-massive"
      class:has-error={hasError}
      bind:this={field}
      value={questionSet.content}
    />
    <div>
      <button class="btn" on:click={save}>Save</button>
    </div>
  </div>
{:else}
  <Loading />
{/if}

<style lang="scss">
  textarea {
    margin-bottom: 1em;
  }

  textarea.has-error {
    border: 1px solid red;
  }

  .panel div {
    display: flex;
  }
  button {
    margin-left: auto;
  }
</style>
