<script lang="ts">
  import { onMount } from "svelte";
  import Router from "./components/pager/router.svelte";
  import Route from "./components/pager/route.svelte";
  import HomePage from "./pages/HomePage.svelte";
  import Projects from "./pages/Projects.svelte";
  import Admins from "./pages/Admins.svelte";
  import Project from "./pages/Project.svelte";
 
  import { useAuth0 } from "./services/auth0";
  import {
    currentUser,
    permissionDenied,
    role,
    authPending,
    loggedOut,
  } from "./stores/store";
  import Header from "./components/Header.svelte";
  import Cleanup from "./pages/Cleanup.svelte";
  import Loading from "./components/Loading.svelte";
  import Facilitators from "./pages/Facilitators.svelte";
  import Participants from "./pages/Participants.svelte";
  import QuestionSet from "./pages/QuestionSet.svelte";
  import Invites from "./pages/Invites.svelte";
  import Stats from "./pages/Stats.svelte";
  import Compare from "./pages/Compare.svelte";
  import DefaultQuestionSet from "./pages/DefaultQuestionSet.svelte";
  import Privacy from "./pages/Privacy.svelte";

  let { isLoading, isAuthenticated, login, logout, initializeAuth0 } = useAuth0;

  const onRedirectCallback = (appState) => {
    window.history.replaceState(
      {},
      document.title,
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  };

  onMount(async () => {
    await initializeAuth0({ onRedirectCallback });
    if (!$isAuthenticated) {
      await login({
        appState: {
          targetUrl: window.location.pathname || "/",
        },
      });
    }
  });
</script>

<Header />
<div class="container">
  {#if $authPending}
    {#if !$loggedOut}
      <Loading />
    {:else}
      <h1>Not logged in</h1>
      <button
        on:click={() => {
          login({ returnTo: window.location.origin });
        }}>Log-in</button
      >
    {/if}
  {:else if $permissionDenied}
    <h1>Permission denied</h1>
    <button
      on:click={() => {
        logout({ returnTo: window.location.origin });
      }}>Logout</button
    >
  {:else}
    <Router>
      <Route path="/" component={HomePage} />
      <Route path="/projects/:projectId/cleanup" component={Cleanup} />
      <Route
        path="/projects/:projectId/facilitators"
        component={Facilitators}
      />
      <Route
        path="/projects/:projectId/participants"
        component={Participants}
      />
     
      <Route path="/projects/:projectId/questions" component={QuestionSet} />
      <Route path="/projects/:projectId/invites" component={Invites} />
      <Route path="/projects/:projectId/stats" component={Stats} />
      <Route path="/projects/:projectId/compare" component={Compare} />
      <Route path="/projects/:projectId" component={Project} />
      <Route path="/projects" component={Projects} />
      <Route path="/admins" component={Admins} />
      <Route path="/settings/questions" component={DefaultQuestionSet} />
      <Route path="/settings/privacy" component={Privacy} />
    </Router>
  {/if}
</div>
