<script lang="ts">
  let expanded = false;
  let expandable = false;
  let height;

  $: expandable = height && height > 28;
</script>

<div
  class="outer"
  class:expanded
  class:expandable
  on:focusin={() => {
    expanded = true;
  }}
>
  <div bind:offsetHeight={height}>
    <slot />
  </div>
  <div
    class="arrow"
    on:click={() => {
      expanded = !expanded;
    }}
  />
</div>

<style lang="scss">
  div.outer {
    height: 28px;
    max-height: 28px;
    overflow: hidden;
    padding: 0;
    position: relative;

    padding-right: 1em;

    .arrow {
      position: absolute;
      top: 8px;
      right: 5px;
      padding: 0;
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='284.929' height='284.929' style='enable-background:new 0 0 284.929 284.929' xml:space='preserve'%3E%3Cpath d='m282.082 76.511-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856-2.471 0-4.661.95-6.563 2.856L142.466 174.441 30.262 62.241c-1.903-1.906-4.093-2.856-6.567-2.856-2.475 0-4.665.95-6.567 2.856L2.856 76.515C.95 78.417 0 80.607 0 83.082c0 2.473.953 4.663 2.856 6.565l133.043 133.046c1.902 1.903 4.093 2.854 6.567 2.854s4.661-.951 6.562-2.854L282.082 89.647c1.902-1.903 2.847-4.093 2.847-6.565 0-2.475-.945-4.665-2.847-6.571z'/%3E%3C/svg%3E");
      width: 12px;
      height: 12px;
      background-size: 100%;
      background-repeat: no-repeat;
      transition: transform 0.3s;
      transform: none;
      display: none;
    }

    &.expanded {
      height: auto;
      max-height: none;

      .arrow {
        transform: scale(1, -1);
      }
    }

    &.expandable .arrow {
      display: block;
    }
  }

  div :global(* > *) {
    // display: none !important;
    padding: 0.5em 1em;
  }
</style>
