<script lang="ts">
  import { useAuth0 } from "../services/auth0";
  import { currentUser, isAdmin, isSuperAdmin } from "../stores/store";
  let { logout } = useAuth0;
</script>

<div class="dropdown">
  <ul>
    <li><a href="/projects">Projects</a></li>
    {#if $isSuperAdmin}
      <li><a href="/admins">Manage administrators</a></li>
      <li><a href="/settings/questions">Manage default question set</a></li>
      <li><a href="/settings/privacy">Manage privacy policy</a></li>
    {/if}
    <li>
      <a
        href="#"
        on:click|capture={() => {
          logout({ returnTo: window.location.origin });
        }}>Log-out</a
      >
    </li>
  </ul>
</div>

<style type="scss">
  .dropdown {
    position: absolute;
    top: 66px;
    right: 36px;
    background: lighten(#e3e2d3, 10);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    padding: 18px;
    z-index: 100;
  }

  li {
    font-weight: 700;
    color: black;
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: inherit;
    display: block;
    padding: 0.5em;
  }
</style>
