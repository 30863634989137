<script>
//need to seperate out more and simplify 
    import { quantize, interpolatePlasma, pie, arc } from 'd3';
  export let data, name, xaxis, yaxis, percentage, percentageValue;
    import {tweened} from 'svelte/motion';


      
    let width = 500 
    let height = width 
    let display = true;
    const percent = true 
    const fontSize = 20; 
    const strokeWidth = 5; 
    const strokeLinejoin = 'round'; 
    $: outerRadius = Math.min(width, height) * 0.5 - 60; 
    $: innerRadius = width/8 < 115 ? width/8 : 115; 
    $: labelRadius = (innerRadius * 0.3 + outerRadius * 0.8); 
   // const strokeColorWOR = 'black'; 
   // const strokeColorWIR = 'none'; 
    const stroke = 'black';
    $: padAngle = stroke === 'none' ? 1 / outerRadius : 0; 
  
    const x = Object.keys(data[0])[0];
    const y = Object.keys(data[0])[1];

    const xVals = data.map((el) => el[x]);
    let yVals = data.map((el) => Number(el[y]));


    if(yVals.every(item => item === 0)){
        display = false;
    }

    if (percent) {
      const total = yVals.reduce((a, b) => a + b, 0);
      yVals = yVals.map((el) => el / total);
    }
    const iVals = data.map((el, i) => i);

    
    let colors = ['#21ab4f'];
   // if (!colors) colors = quantize(t => interpolatePlasma(t * 0.8 + 0.3), xVals.length);
  
    $: wedges = pie()
      .padAngle(padAngle)
      .sort(null)
      .value(i => yVals[i])(iVals);
  
    $: arcPath = arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius);
  
    $: arcLabel = arc()
      .innerRadius(labelRadius)
      .outerRadius(labelRadius);


    

  </script>
{#if !display}
    <h2 class="center">There is not enough data in the project to display this graph</h2>
{:else}
<div class="wrap-h2">
<h2>{name}</h2>
</div>
  <div class="chart" bind:clientWidth={width} bind:clientHeight={height}>
  <svg viewBox="{-width / 2} {-height / 2} {width} {height}">

      <path fill={colors[0]} d={arcPath(wedges[0])} stroke={stroke} stroke-width={strokeWidth} stroke-linejoin={strokeLinejoin} />
      <circle stroke="black" r={outerRadius} fill="none"/>
      <circle stroke="black" r={innerRadius} fill="none"/>
      <g text-anchor='middle'>
          <text font-size={fontSize}>
                 <tspan font-weight="bold">{percent ? `${(yVals[0] * 100).toFixed(0)}%` : yVals[0].toLocaleString('en-UK')}</tspan>
          </text>
      </g>

  </svg>
  </div>
  {/if}
