<script lang="ts">
  import { onMount } from "svelte";
  import {
    deleteFacilitator,
    getFacilitators,
    getProject,
  } from "../services/project";
  import Loading from "../components/Loading.svelte";
  import ProjectTabs from "../components/ProjectTabs.svelte";
  import CreateFacilitator from "../components/CreateFacilitator.svelte";
  import { fade } from "svelte/transition";
  import { pageTitle } from "../stores/store";
  export let projectId;
  let facilitators;
  let dangerEnabled = false;
  onMount(async () => {
    facilitators = await getFacilitators(projectId);
    let project = await getProject(projectId);
    $pageTitle = project.name;
  });
  const onAdded = async () => {
    facilitators = await getFacilitators(projectId);
  };
  const deleteUser = async (user) => {
    const result = await deleteFacilitator(projectId, user.email);
    if (result && result.success) {
      facilitators = facilitators.filter((f) => {
        return f.email != user.email;
      });
    }
  };
</script>

<ProjectTabs {projectId} />
<div class="panel" class:show-danger={dangerEnabled}>
  <div class="grid">
    <div class="cell is-first">
      {#if facilitators}
        <table style="max-width:500px">
          <thead>
            <th>Email address</th>
            <th
              class="is-danger"
              width="160"
              on:click={() => {
                dangerEnabled = !dangerEnabled;
              }}>Danger zone</th
            >
          </thead>
          <tbody>
            {#each facilitators as f, index (f._id)}
              <tr>
                <td>
                  {f.email}
                </td>

                <td class="is-danger">
                  <button
                    class="btn"
                    on:click={() => {
                      deleteUser(f);
                    }}>Delete</button
                  >
                </td>
              </tr>
            {/each}
            {#if facilitators.length === 0}
              <tr>
                <td colspan="2">No facilitators have been created</td>
              </tr>
            {/if}
          </tbody>
        </table>
      {:else}
        <Loading />
      {/if}
    </div>
    <div class="cell">
      <CreateFacilitator {projectId} on:created={onAdded} />
    </div>
  </div>
</div>

<style lang="scss">
  div.is-first {
    padding-right: 3rem;
  }
</style>
