<script lang="ts">
  export let date;
  export let change = () => {};
  export let enabled = true;

  let formattedDate;
  $: {
    formattedDate = date ? new Date(date).toISOString().split("T")[0] : null;
  }
  let field;
</script>

<input
  type="date"
  bind:this={field}
  disabled={!enabled}
  on:click={() => {
    if (!enabled) {
      return;
    }
    field.showPicker();
  }}
  on:change={() => {
    if (!enabled) {
      return;
    }
    date = new Date(formattedDate).toISOString();
    change();
  }}
  bind:value={formattedDate}
/>
